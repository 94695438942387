
.main-wrapper {
    background: url("../../../../assets/images/student/student-sct-bg.png") no-repeat;
    background-size: 100% 100%;
    height: 100%;
    position: relative;

    .main-wrapper-scrollbar {
        height: 100%;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .wrapper-list {
            .top-title {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                font-size: 48px;
                color: #35c773;
                padding-top: 20px;
                letter-spacing: 4px;
                font-weight: bold;
                /*margin-bottom: 28px;*/
                position: relative;

                .title {
                    position: absolute;
                    top: 0;
                }

                &:before {
                    content: url("../../../../assets/images/student/line-left.png");
                    margin-right: 15px;
                }

                &:after {
                    content: url("../../../../assets/images/student/line-right.png");
                    margin-left: 15px;
                }

                .backBtn {
                    position: absolute;
                    right: 40px;
                    top: 0;
                }

            }

            .time-item {
                /*position: absolute;*/
                /*top: 180px;*/
                margin-top: 80px;
                width: 100%;
                display: flex;
                justify-content: center;

                .time-item-list {
                    display: flex;
                    height: 80px;
                    line-height: 80px;
                    background: #39B678;

                    .time-title-left {
                        flex: 1;
                        display: flex;
                        border: 6px solid #61a885;
                        background: #DDFDF2;

                        .comma {
                            color: #31A588;
                            font-size: 66px;
                            letter-spacing: 18px;
                            text-align: center;
                            line-height: 33px;
                            display: inline-block;
                        }

                        .item {
                            flex: 1;
                            font-size: 24px;
                            padding: 0 40px;
                            color: #31A588;
                            position: relative;

                            &:after {
                                content: '';
                                height: 40px;
                                width: 3px;
                                background: #31A588;
                                position: absolute;
                                top: 14px;
                                right: 10px;
                            }

                            &:before {
                                content: '';
                                height: 40px;
                                width: 3px;
                                background: #31A588;
                                position: absolute;
                                top: 14px;
                                left: 10px;
                            }
                        }
                    }

                    .start-tex {
                        width: 120px;
                        font-size: 24px;
                        color: #fff;
                        cursor: pointer;
                        text-align: center;
                    }
                }

            }
        }

        .mission-wrapper {
            width: 100%;

            .mission-title {
                width: 100%;
                height: 80px;
                text-align: center;
                font-size: 48px;
                font-style: italic;
                font-weight: 600;
                position: relative;
                margin-top: 40px;

                .line {
                    position: absolute;
                    top: 40px;
                    left: calc(50% - 150px);
                    width: 300px;
                    height: 20px;
                    background: #FEDB2E;
                }

                .mission-title-text {
                    position: inherit;
                    /*left: calc(50% - 100px);*/
                    text-align: center;
                }
            }

            .task-item {
                margin-top: 36px;

                .task-bg {
                    color: #fff;
                    position: relative;
                    margin-left: 50px;

                    .num-title {
                        font-size: 34px;
                        position: absolute;
                        top: -26px;
                        left: 50px;

                        em {
                            font-style: normal;
                        }

                    }
                }

                .task-content {
                    background: #DDFDF2;
                    padding: 20px 20px 20px 50px;
                    margin: -20px 20px;

                    ::v-deep {
                        img {
                            max-width: 100%;
                        }

                        p {
                            margin: 0;
                        }
                    }

                    ::v-deep.text {
                        table {
                            border-collapse: collapse !important;
                            width: 100% !important;
                            border-color: transparent;
                            border-bottom: none;
                            margin-top: -4px;

                            tr {
                                height: auto !important;

                                &:nth-child(even) {
                                    td {
                                        background: #F2F9FA;
                                        color: #333333;
                                    }
                                }

                                &:nth-child(odd) {
                                    td {
                                        background: #C4F2E4;
                                    }
                                }

                                th {
                                    background: #56CA95;
                                }

                                th, td {
                                    border-right: 4px solid #fff;
                                    border-top: 4px solid #fff;

                                    &:first-child {
                                        border-left: 0;
                                        /*text-align: center;*/
                                    }

                                    &:last-child {
                                        border-right: 0;
                                        /*text-align: center;*/
                                    }
                                }

                                th {
                                    text-align: center;
                                    padding: 20px;
                                    height: 20px !important;
                                    line-height: 20px;
                                    box-sizing: border-box;
                                    font-size: 18px;
                                    color: #fff;
                                    white-space: nowrap;
                                }

                                td {
                                    height: auto !important;
                                    text-align: center;
                                    padding: 14px;
                                    font-size: 18px;
                                    color: #fff;
                                }
                            }
                        }
                    }

                    ::v-deep.greenTable {
                        color: #333;
                        font-size: 16px;

                        th {
                            height: 60px;
                            font-weight: normal;
                            background: #C4F2E4;
                            border-top: 1px solid #eee;

                            &:first-child {
                                border-left: 1px solid #eee;
                            }

                            &:nth-last-child(2) {
                                border-right: 1px solid #eee;
                            }

                            &.gutter {
                                border-right: 1px solid #eee;
                            }
                        }

                        td {
                            &:first-child {
                                border-left: 1px solid #eee;
                            }

                            &:last-child {
                                border-right: 1px solid #eee;
                            }
                        }

                        th, td {
                            border-right: 4px solid #fff;
                            border-top: 4px solid #fff;

                            &:first-child {
                                border-left: 0;
                                /*text-align: center;*/
                            }

                            &:last-child {
                                border-right: 0;
                                /*text-align: center;*/
                            }
                        }

                        tr {
                            height: 60px;
                            background: #F2F9FA;

                            &:nth-of-type(even) {
                                background: #C4F2E4;
                            }
                        }
                    }
                }
            }
        }
    }
}
